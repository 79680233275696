import {
  Box,
  Text,
  Textarea,
  FormControl,
  FormLabel,
  FormHelperText,
  Select,
  Stack,
  Heading,
  Input,
  FormErrorMessage,
  Flex,
  RadioGroup,
  Radio,
  InputRightElement,
  InputGroup,
} from "@chakra-ui/react";
import { PrimaryButton, CancelButton, PrimaryLinkButton } from "../../components/buttons";
import { PanelGroup, PanelTop, PanelBottom } from "../../components/panels";
import { Loader } from "../../components/loader";
import { useClientDetailsQuestionnaire } from "./use-client-questionnaire";
import { QuestionnaireStatusIndicator } from "../../common/questionnaire-status-indicator/questionnaire-status-indicator";
import { VOICE_OPTIONS } from "../../constants/voice-options";
import { PURPOSE_OF_CONTENT_OPTIONS } from "../../constants/purpose-of-content-options";
import { AdminOnly } from "../../common/admin-only";

interface Props {
  customerId: number;
}

const contentPermissionOptions = [
  {
    value: "I will publish new content on my site",
    name: "I will publish new content on my site",
  },
  {
    value: "Add all new content to my site as an unpublished draft",
    name: "Add all new content to my site as an unpublished draft",
  },
  {
    value: "Publish all new content for me",
    name: "Publish all new content for me",
  },
];

export function ContentDetails(props: Props) {
  const { customerId } = props;

  const { contentFormik, isQuestionnaireLoading, showPassword, onPasswordClick } =
    useClientDetailsQuestionnaire(customerId);

  function onCancel() {
    contentFormik.resetForm();
    setTimeout(async () => await contentFormik.validateForm(), 0);
  }

  return (
    <PanelGroup isCollapsible>
      <PanelTop showBottomBorder>
        <Flex justifyContent="space-between">
          <Box>
            <Heading size="md">Content Details</Heading>
            <Text fontSize="sm" color="#828282">
              Takes about 5 minutes
            </Text>
          </Box>
          <Box>
            <QuestionnaireStatusIndicator values={contentFormik.values} isValid={contentFormik.isValid} />
          </Box>
        </Flex>
      </PanelTop>
      <PanelBottom>
        {isQuestionnaireLoading && <Loader type="content" />}
        {!isQuestionnaireLoading && (
          <Stack spacing={4}>
            <FormControl isRequired isInvalid={!!contentFormik.errors.contentPublishingPermissions}>
              <FormLabel>Content Publishing Permissions</FormLabel>
              <Select
                id="contentPublishingPermissions"
                value={contentFormik.values.contentPublishingPermissions}
                onChange={contentFormik.handleChange}
                placeholder="Not provided"
                variant="outline"
              >
                {contentPermissionOptions.map((option) => (
                  <option value={option.value} key={option.value}>
                    {option.name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{contentFormik.errors.contentPublishingPermissions}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!contentFormik.errors.isRCMPublishing}>
              <FormLabel>Is RedCanyonMedia publishing onsite blogs?</FormLabel>
              <RadioGroup
                id="isRCMPublishing"
                value={contentFormik.values.isRCMPublishing ? "yes" : "no"}
                onChange={(value) => {
                  contentFormik.setFieldValue("isRCMPublishing", value === "yes");
                }}
              >
                <Stack spacing={5} isInline>
                  <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                </Stack>
              </RadioGroup>
              <FormErrorMessage>{contentFormik.errors.isRCMPublishing}</FormErrorMessage>
            </FormControl>
            {contentFormik.values.isRCMPublishing && (
              <>
                <FormControl isRequired isInvalid={!!contentFormik.errors.adminUrl}>
                  <FormLabel>Admin URL</FormLabel>
                  <Input
                    id="adminUrl"
                    value={contentFormik.values.adminUrl}
                    onChange={contentFormik.handleChange}
                    variant="outline"
                  />
                  <FormErrorMessage>{contentFormik.errors.adminUrl}</FormErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={!!contentFormik.errors.user}>
                  <FormLabel>User Name</FormLabel>
                  <Input
                    id="user"
                    value={contentFormik.values.user}
                    onChange={contentFormik.handleChange}
                    variant="outline"
                  />
                  <FormErrorMessage>{contentFormik.errors.user}</FormErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={!!contentFormik.errors.password}>
                  <FormLabel>Password</FormLabel>
                  <InputGroup size="md">
                    <Input
                      id="password"
                      type={showPassword ? "text" : "password"}
                      value={contentFormik.values.password}
                      onChange={contentFormik.handleChange}
                      variant="outline"
                    />
                    <InputRightElement width="4.5rem">
                      <PrimaryLinkButton h="1.75rem" size="sm" onClick={onPasswordClick}>
                        {showPassword ? "Hide" : "Show"}
                      </PrimaryLinkButton>
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>{contentFormik.errors.password}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!contentFormik.errors.instructions}>
                  <FormLabel>Instructions</FormLabel>
                  <Textarea
                    value={contentFormik.values.instructions}
                    id="instructions"
                    name="instructions"
                    onChange={contentFormik.handleChange}
                    resize="none"
                  />
                  <FormErrorMessage>{contentFormik.errors.instructions}</FormErrorMessage>
                </FormControl>
              </>
            )}
            <FormControl isRequired isInvalid={!!contentFormik.errors.targetAudience}>
              <FormLabel>Target Audience</FormLabel>
              <Input
                id="targetAudience"
                value={contentFormik.values.targetAudience}
                onChange={contentFormik.handleChange}
                variant="outline"
              />
              <FormErrorMessage>{contentFormik.errors.targetAudience}</FormErrorMessage>
              <FormHelperText>Who is your target audience?</FormHelperText>
            </FormControl>
            <FormControl isRequired isInvalid={!!contentFormik.errors.preferredVoice}>
              <FormLabel>Brand Voice</FormLabel>
              <Select
                id="preferredVoice"
                value={contentFormik.values.preferredVoice}
                onChange={contentFormik.handleChange}
                placeholder="Not provided"
                variant="outline"
              >
                {VOICE_OPTIONS.map((option) => (
                  <option value={option.value} key={option.value}>
                    {option.name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{contentFormik.errors.preferredVoice}</FormErrorMessage>
              <FormHelperText>
                Please select your preferred brand voice to be used for blog content published to your site.
              </FormHelperText>
            </FormControl>
            <FormControl
              isInvalid={!!contentFormik.touched.purposeOfContent && !!contentFormik.errors.purposeOfContent}
            >
              <FormLabel>Purpose of Content</FormLabel>
              <Select
                id="purposeOfContent"
                value={contentFormik.values.purposeOfContent}
                onChange={contentFormik.handleChange}
                variant="outline"
                placeholder="Not provided"
              >
                {PURPOSE_OF_CONTENT_OPTIONS.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{contentFormik.errors.purposeOfContent}</FormErrorMessage>
              <FormHelperText>What is your primary business goal at this time?</FormHelperText>
            </FormControl>
            <FormControl isInvalid={!!contentFormik.errors.isAddImage}>
              <FormLabel>Would you like a featured image provided with onsite content?</FormLabel>
              <Select
                id="isAddImage"
                value={contentFormik.values.isAddImage}
                onChange={contentFormik.handleChange}
                placeholder="Not provided"
                variant="outline"
              >
                <option value="1" key="true">
                  Yes
                </option>
                <option value="0" key="false">
                  No
                </option>
              </Select>
              <FormErrorMessage>{contentFormik.errors.isAddImage}</FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={!!contentFormik.touched.callToActionUrls && !!contentFormik.errors.callToActionUrls}
            >
              <FormLabel>Call to Action Link</FormLabel>
              <Textarea
                id="callToActionUrls"
                value={contentFormik.values.callToActionUrls}
                onChange={contentFormik.handleChange}
                resize="none"
                placeholder="Not provided"
                variant="outline"
              />
              <FormErrorMessage>{contentFormik.errors.callToActionUrls}</FormErrorMessage>
              <FormHelperText>
                Do you have a specific link or set of links you want us to use as a call to action (i.e. landing page,
                sign up page, or Calendly url)
              </FormHelperText>
            </FormControl>
            <FormControl
              isInvalid={!!contentFormik.touched.competitorWebsites && !!contentFormik.errors.competitorWebsites}
            >
              <FormLabel>List 3-5 competitor company websites</FormLabel>
              <Textarea
                id="competitorWebsites"
                value={contentFormik.values.competitorWebsites}
                onChange={contentFormik.handleChange}
                variant="outline"
                resize="none"
                placeholder="Not provided"
              />
              <FormErrorMessage>{contentFormik.errors.competitorWebsites}</FormErrorMessage>
              <FormHelperText>
                We utilize this information to ensure we never link to or from your competitor for any reason.
              </FormHelperText>
            </FormControl>
            <FormControl isInvalid={!!contentFormik.touched.targetKeywords && !!contentFormik.errors.targetKeywords}>
              <FormLabel>
                What keywords or phrases would you or your customers use to find your business online?
              </FormLabel>
              <Textarea
                id="targetKeywords"
                value={contentFormik.values.targetKeywords}
                onChange={contentFormik.handleChange}
                resize="none"
                placeholder="If you were to search for your company products or services today, what key search terms would you use..."
                variant="outline"
              />
              <FormErrorMessage>{contentFormik.errors.targetKeywords}</FormErrorMessage>
              <FormHelperText>
                If you were to search for your company products or services today, what key search terms would you
                use...
              </FormHelperText>
            </FormControl>
            <AdminOnly>
              <FormControl isRequired isInvalid={!!contentFormik.errors.isBlogFulfilledByRCMWriter}>
                <FormLabel>Is RedCanyonMedia writing blogs?</FormLabel>
                <RadioGroup
                  id="isBlogFulfilledByRCMWriter"
                  value={contentFormik.values.isBlogFulfilledByRCMWriter ? "yes" : "no"}
                  onChange={(value) => {
                    contentFormik.setFieldValue("isBlogFulfilledByRCMWriter", value === "yes");
                  }}
                >
                  <Stack spacing={5} isInline>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </Stack>
                </RadioGroup>
                <FormErrorMessage>{contentFormik.errors.isBlogFulfilledByRCMWriter}</FormErrorMessage>
              </FormControl>
            </AdminOnly>
            <AdminOnly>
              <FormControl isRequired isInvalid={!!contentFormik.errors.isGuestArticleFulfilledByRCMWriter}>
                <FormLabel>Is RedCanyonMedia writing guest articles?</FormLabel>
                <RadioGroup
                  id="isGuestArticleFulfilledByRCMWriter"
                  value={contentFormik.values.isGuestArticleFulfilledByRCMWriter ? "yes" : "no"}
                  onChange={(value) => {
                    contentFormik.setFieldValue("isGuestArticleFulfilledByRCMWriter", value === "yes");
                  }}
                >
                  <Stack spacing={5} isInline>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </Stack>
                </RadioGroup>
                <FormErrorMessage>{contentFormik.errors.isGuestArticleFulfilledByRCMWriter}</FormErrorMessage>
              </FormControl>
            </AdminOnly>
            <AdminOnly>
              <FormControl isRequired isInvalid={!!contentFormik.errors.isAutoSelectLinkLocation}>
                <FormLabel>Is Auto Select Link Location?</FormLabel>
                <RadioGroup
                  id="isAutoSelectLinkLocation"
                  value={contentFormik.values.isAutoSelectLinkLocation ? "yes" : "no"}
                  onChange={(value) => {
                    contentFormik.setFieldValue("isAutoSelectLinkLocation", value === "yes");
                  }}
                >
                  <Stack spacing={5} isInline>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </Stack>
                </RadioGroup>
                <FormErrorMessage>{contentFormik.errors.isAutoSelectLinkLocation}</FormErrorMessage>
              </FormControl>
            </AdminOnly>
            {contentFormik.dirty && (
              <Box>
                <PrimaryButton onClick={contentFormik.submitForm} marginRight="8px">
                  Save
                </PrimaryButton>
                <CancelButton onClick={onCancel}>Cancel</CancelButton>
              </Box>
            )}
          </Stack>
        )}
      </PanelBottom>
    </PanelGroup>
  );
}
