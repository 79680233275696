import Papa from "papaparse";
import { useState } from "react";
import { Flex, Box, Text, Heading, useToast } from "@chakra-ui/react";
import { LayoutBase } from "../../components/layout";
import { Container } from "../../components/layout/container";
import { PanelGroup, PanelBottom, PanelTop } from "../../components/panels";
import { PrimaryButton } from "../../components/buttons";
import { useCreateCustomerKeywordFromKeywordsMutation, useCustomerKeywordsQuery } from "../../generated/graphql";
import { KeywordsTable } from "./keywords-table";
import { PageHeading } from "../../components/page-heading";
import { AppColors } from "../../core/custom-theme";
import ErrorCard from "../../components/error-card";
import { AddClientKeywordDrawer } from "../../common/add-client-keyword";
import { CustomSingleSelect } from "../../common/custom-selector";
import { SEARCH_ENGINES } from "../../constants/search-engines";
import { FormHelpers } from "../../util/form-helpers";
import { AdminOnly } from "../../common/admin-only";
import { FileDropUpload } from "../../components/file-drop-upload";

interface Props {
  customerId: number;
}

export function ClientDetailsKeywords(props: Props) {
  const { customerId } = props;
  const toast = useToast();
  const [showAddKeyword, setShowAddKeyword] = useState<boolean>(false);
  const [searchEngine, setSearchEngine] = useState<string>(SEARCH_ENGINES.GOOGLE);
  const customerKeywordsQuery = useCustomerKeywordsQuery({
    variables: {
      customerId,
      includeInactive: true,
      searchEngine: searchEngine,
      isTrackingMapRank: searchEngine === SEARCH_ENGINES.GOOGLE_MAP ? true : undefined,
    },
  });
  const [createCustomerKeyword] = useCreateCustomerKeywordFromKeywordsMutation();

  if (customerKeywordsQuery.error) {
    return <ErrorCard error={customerKeywordsQuery.error} />;
  }

  const onAddKeywordDrawerClose = () => {
    setShowAddKeyword(false);
  };

  const onAddKeywordDrawerAdded = () => {
    customerKeywordsQuery.refetch();
  };

  const onShowAddKeywordDrawer = () => {
    setShowAddKeyword(true);
  };

  const onSearchEngineChange = (value: string | null) => {
    setSearchEngine(value ?? SEARCH_ENGINES.GOOGLE);
  };

  const searchEngineItems = [
    {
      value: SEARCH_ENGINES.GOOGLE,
      displayText: "Organic",
    },
    {
      value: SEARCH_ENGINES.GOOGLE_MAP,
      displayText: "Map",
    },
  ];

  const onFileSelected = async (file: any) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (results: any) => {
        let successCount = 0;
        let errorCount = 0;
        for (const result of results.data) {
          const response = await createCustomerKeyword({
            variables: {
              input: {
                customerId,
                name: result.name,
                location: FormHelpers.processNullableString(result.location ?? ""),
                country: FormHelpers.processNullableString(result.country ?? ""),
                landingPageUrl: FormHelpers.processNullableString(result.landingPageUrl ?? ""),
                instructions: result.instructions,
                isPrimary: result.isPrimary === "TRUE",
              },
            },
          });
          if (response.data?.createCustomerKeyword.customerKeyword.id) {
            successCount++;
          } else {
            errorCount++;
          }
        }
        toast({
          title: "File uploaded",
          description: `${successCount} keywords added, ${errorCount} keywords failed to add`,
          status: "success",
        });
        await customerKeywordsQuery.refetch();
      },
    });
  };

  return (
    <LayoutBase pageTitle="Keywords">
      <AddClientKeywordDrawer
        clientId={customerId}
        isOpen={showAddKeyword}
        onAdded={onAddKeywordDrawerAdded}
        onClose={onAddKeywordDrawerClose}
      />
      <Container centerHorizontally>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems="center">
            <PageHeading marginRight={4}>Keywords</PageHeading>
            <CustomSingleSelect
              items={searchEngineItems}
              value={searchEngine}
              onChange={onSearchEngineChange}
              allowEmpty={false}
            />
          </Flex>
          <Flex>
            <AdminOnly requiresSuperAdmin>
              <Box>
                <FileDropUpload
                  acceptedFileExtensions={{
                    "application/csv": [".csv"],
                  }}
                  onFileUpload={onFileSelected}
                />
              </Box>
            </AdminOnly>
            <PrimaryButton onClick={onShowAddKeywordDrawer}>Add Keyword</PrimaryButton>
          </Flex>
        </Flex>
        <PanelGroup>
          <PanelTop showBottomBorder>
            <Flex justifyContent="space-between" alignItems="center">
              <Box>
                <Heading size="md">Primary Keywords</Heading>
              </Box>
              <Box>
                <Text color={AppColors.silver} fontSize={12} maxWidth={400} textAlign="right">
                  Warning: Toggling keywords from active to inactive will de-activate any keywords already associated
                  with a content strategy topic
                </Text>
              </Box>
            </Flex>
          </PanelTop>
          <PanelBottom containsTable>
            <KeywordsTable customerKeywordsQueryResult={customerKeywordsQuery} isPrimary searchEngine={searchEngine} />
          </PanelBottom>
        </PanelGroup>
        <PanelGroup>
          <PanelTop showBottomBorder>
            <Flex justifyContent="space-between" alignItems="flex-start">
              <Heading size="md">Halo Keywords</Heading>
            </Flex>
          </PanelTop>
          <PanelBottom containsTable>
            <KeywordsTable
              customerKeywordsQueryResult={customerKeywordsQuery}
              isPrimary={false}
              searchEngine={searchEngine}
            />
          </PanelBottom>
        </PanelGroup>
      </Container>
    </LayoutBase>
  );
}
