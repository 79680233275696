import { DateTime } from "luxon";
import { Flex, Box, Stack, Text, FormControl, FormLabel, Button, HStack } from "@chakra-ui/react";
import { LayoutBase } from "../../components/layout";
import { MainNav } from "../../components/layout";
import { PageHeading } from "../../components/page-heading";
import { Container } from "../../components/layout/container";
import { PanelGroup, PanelBottom, PanelTop } from "../../components/panels";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { AddClientDrawer } from "../../common/add-client-drawer";
import { SearchInput } from "../../components/search-input/search-input";
import { useClients } from "./clients-hook";
import {
  Table,
  TableBody,
  TableCell,
  TableFoot,
  TableHead,
  TableHeaderCell,
  TablePagination,
  TableRow,
} from "../../components/table";
import { HelpText } from "../../components/help-text";
import AppLink from "../../components/app-link";
import { TruncatedText } from "../../components/truncated-text";
import { CustomerStatusIndicator } from "../../common/customer-status-indicator/customer-status-indicator";
import { CustomerQuestionnaireStatusIndicator } from "../../common/customer-questionnaire-status-indicator/customer-questionnaire-status-indicator";
import { CustomMultiSelect, CustomSingleSelect } from "../../common/custom-selector";
import { PartnerMultiSelect } from "../../common/partner-selector-v2";
import { faBan, faColumns, faSort } from "@fortawesome/free-solid-svg-icons";
import AppIcon from "../../components/app-icon";
import { CustomersSort } from "../../generated/graphql";
import { DATE_MONTH_FORMAT } from "../../constants/constants";
import { CampaignCategorySelectorV2 } from "../../common/campaign-category-selector-v2";
import { AdminOnly } from "../../common/admin-only";
import { UserSelectorV2 } from "../../common/user-selector-v2";
import { FileDropUpload } from "../../components/file-drop-upload";

export function ClientsRoute() {
  const {
    accessiblePartnerCount,
    clearFilters,
    closeAddClientDrawer,
    // closeFilters,
    customers,
    filterCount,
    // isFiltersOpen,
    loading,
    onAddClient,
    onClientAdded,
    onPartnerIdsChange,
    onSearchChange,
    onSortChange,
    onStatusIdsChange,
    onCampaignCategoryChange,
    pagination,
    partnerIds,
    showAddClientDrawer,
    sort,
    statusIds,
    campaignCategoryId,
    totalCount,
    userId,
    onUserSelected,
    partnerType,
    onPartnerTypeChange,
    onFileSelected,
  } = useClients();

  return (
    <LayoutBase pageTitle="Clients">
      <MainNav />
      <AddClientDrawer isOpen={showAddClientDrawer} onAdded={onClientAdded} onClose={closeAddClientDrawer} />
      <Container centerHorizontally>
        <PageHeading>Clients</PageHeading>
        <PanelGroup>
          <PanelTop showBottomBorder>
            <Flex justifyContent="space-between" alignItems="center">
              <HStack spacing="8px" justify="left" justifyContent="flex-start" alignItems="flex-end">
                <FormControl width="inherit">
                  <FormLabel fontSize="xs">Filter</FormLabel>
                  <SearchInput onChange={onSearchChange} />
                </FormControl>
                <FormControl width="inherit">
                  <FormLabel fontSize="xs">Sort</FormLabel>
                  <CustomSingleSelect
                    onChange={(value) => {
                      if (!value) {
                        return null;
                      }
                      onSortChange(value);
                    }}
                    allowEmpty={false}
                    value={sort}
                    items={[
                      { displayText: "Oldest First", value: CustomersSort.IdAsc },
                      { displayText: "Newest First", value: CustomersSort.IdDesc },
                      { displayText: "Name Ascending (A-Z)", value: CustomersSort.NameAsc },
                      { displayText: "Name Descending (Z-A)", value: CustomersSort.NameDesc },
                      {
                        displayText: "Campaign End Oldest First",
                        value: CustomersSort.EndDateAsc,
                        isDisabled: !campaignCategoryId,
                      },
                      {
                        displayText: "Campaign End Newest First",
                        value: CustomersSort.EndDateDesc,
                        isDisabled: !campaignCategoryId,
                      },
                    ]}
                    selectorIcon={faSort}
                  />
                </FormControl>
                <FormControl width="inherit">
                  <FormLabel fontSize="xs">Status</FormLabel>
                  <CustomMultiSelect
                    onChange={(values) => {
                      onStatusIdsChange(values);
                    }}
                    value={statusIds as string[]}
                    items={[
                      { displayText: "Active", value: "active" },
                      { displayText: "Cancelled", value: "cancelled" },
                      { displayText: "Paused", value: "paused" },
                    ]}
                    pluralizedItemName="States"
                    emptyValueTitle="All"
                    selectorIcon={faColumns}
                  />
                </FormControl>
                <FormControl width="inherit">
                  <FormLabel fontSize="xs">Partner</FormLabel>
                  <PartnerMultiSelect selectedPartnerIds={partnerIds as number[]} onChange={onPartnerIdsChange} />
                </FormControl>
                <AdminOnly>
                  <FormControl width="inherit">
                    <FormLabel fontSize="xs">Partner Type</FormLabel>
                    <CustomSingleSelect
                      onChange={onPartnerTypeChange}
                      value={partnerType}
                      items={[
                        { displayText: "RCM Partners", value: "partner" },
                        { displayText: "LMR Partners", value: "lmr" },
                      ]}
                      emptyValueTitle="All Partner Type"
                      selectorIcon={faColumns}
                      allowEmpty={true}
                    />
                  </FormControl>
                </AdminOnly>
                <FormControl width="inherit">
                  <FormLabel fontSize="xs">Campaign Category</FormLabel>
                  <CampaignCategorySelectorV2
                    selectedCampaignCategoryId={campaignCategoryId}
                    onCampaignCategorySelected={onCampaignCategoryChange}
                    showAll={true}
                  />
                </FormControl>
                <AdminOnly>
                  <FormControl width="inherit">
                    <FormLabel fontSize="xs">RCM Account Manager</FormLabel>
                    <UserSelectorV2
                      onUserSelected={onUserSelected}
                      selectedUserId={userId}
                      isAdminOrSuperAdmin={true}
                      showAll={true}
                    />
                  </FormControl>
                </AdminOnly>
                <Box>
                  {filterCount > 0 && (
                    <Button onClick={clearFilters}>
                      <AppIcon icon={faBan} standardRightMargin />
                      Clear Filters
                    </Button>
                  )}
                </Box>
              </HStack>
              <Box>
                <Stack isInline spacing={4} shouldWrapChildren>
                  <PrimaryButton onClick={onAddClient}>Add Client</PrimaryButton>
                </Stack>
              </Box>
            </Flex>
            <Flex marginTop={2} marginLeft={2}>
              <AdminOnly requiresSuperAdmin>
                <Box>
                  <FileDropUpload
                    acceptedFileExtensions={{
                      "application/csv": [".csv"],
                    }}
                    onFileUpload={onFileSelected}
                  />
                </Box>
              </AdminOnly>
            </Flex>
          </PanelTop>
          <PanelBottom containsTable>
            <Table isEmpty={customers.length === 0} isLoading={loading}>
              <TableHead>
                <TableRow key="head">
                  <TableHeaderCell>Name</TableHeaderCell>
                  <TableHeaderCell>
                    Status
                    <HelpText standardLeftMargin>
                      <Stack spacing={2}>
                        <Text fontSize="sm">Current client status.</Text>
                        <Text fontSize="sm">Active - workflow is active.</Text>
                        <Text fontSize="sm">
                          Paused - future workflow is paused and will not be delivered until restarted
                        </Text>
                        <Text fontSize="sm">Cancelled - future work is removed</Text>
                        <Text fontSize="sm">
                          Note: Already assigned work will be completed regardless of when a status is updated. Paused
                          workflows will skip all work that would be assigned during the pause and pick up roadmap items
                          at time of re-activation.{" "}
                        </Text>
                      </Stack>
                    </HelpText>
                  </TableHeaderCell>
                  <TableHeaderCell>
                    Brand Manager
                    <HelpText standardLeftMargin>
                      <Stack spacing={2}>
                        <Text fontSize="sm">Person in charge of this client.</Text>
                        <Text fontSize="sm">
                          Note: Internal messages to this client will be sent from the assigned brand manager in charge
                          of the account.
                        </Text>
                      </Stack>
                    </HelpText>
                  </TableHeaderCell>
                  <TableHeaderCell>
                    Onboarding
                    <HelpText standardLeftMargin>
                      <Stack spacing={2}>
                        <Text fontSize="sm">Status of client onboarding process.</Text>
                        <Text fontSize="sm">Not Started - client has not activated their account</Text>
                        <Text fontSize="sm">
                          In Progress - client has activated their account and still needs to complete their onboarding
                          process
                        </Text>
                        <Text fontSize="sm">Complete - client has completed their onboarding</Text>
                      </Stack>
                    </HelpText>
                  </TableHeaderCell>
                  <TableHeaderCell>
                    Campaign End Date
                    <HelpText standardLeftMargin>
                      <Stack spacing={2}>
                        <Text fontSize="sm">Campaign End Date according to Campaign Category</Text>
                      </Stack>
                    </HelpText>
                  </TableHeaderCell>
                  <TableHeaderCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {customers.map((customer) => {
                  // find the most future endsAt date
                  let endsAt = undefined;
                  if (campaignCategoryId) {
                    endsAt = customer.customerCampaigns.nodes.reduce((result, { endsAt }) => {
                      if (!result || result < endsAt) {
                        result = endsAt;
                      }
                      return result;
                    }, undefined as undefined | Date);
                  }

                  return (
                    <TableRow key={customer.id}>
                      <TableCell>
                        <AppLink to={`/clients/${customer.id}`}>{customer.name}</AppLink>
                        {accessiblePartnerCount > 1 && (
                          <TruncatedText maxLength={30} fontSize="sm">
                            {customer.partner.name}
                          </TruncatedText>
                        )}
                      </TableCell>
                      <TableCell>
                        <CustomerStatusIndicator status={customer.status} />
                      </TableCell>
                      <TableCell>{customer.accountManagerUser?.fullName ?? "-"}</TableCell>
                      <TableCell>
                        <CustomerQuestionnaireStatusIndicator customerQuestionnaire={customer.customerQuestionnaire} />
                      </TableCell>
                      <TableCell>{endsAt ? DateTime.fromJSDate(endsAt).toFormat(DATE_MONTH_FORMAT) : "-"}</TableCell>
                      <TableCell>
                        <Box textAlign="right">
                          <AppLink to={`/clients/${customer.id}`}>
                            <SecondaryButton size="sm">View</SecondaryButton>
                          </AppLink>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFoot>
                <TablePagination totalCount={totalCount} {...pagination} />
              </TableFoot>
            </Table>
          </PanelBottom>
        </PanelGroup>
      </Container>
    </LayoutBase>
  );
}
