import { LinkLocationsQueryResult } from "../../generated/graphql";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeaderCell,
  TableCell,
  TablePagination,
  TableFoot,
} from "../../components/table";
import AppLink from "../../components/app-link";
import { CategoryMultiSelector } from "../../common/category-selector";
import { DateTime } from "luxon";
import { DATE_MONTH_FORMAT } from "../../constants/constants";
import { Switch } from "@chakra-ui/react";

interface Props {
  adminLinkLocationsQueryResult: LinkLocationsQueryResult;
  onNextPage: () => void;
  onPreviousPage: () => void;
  updateLinkLocationCategories: (linkLocationId: number, categoryIds: number[]) => void;
  updateLinkLocation: (linkLocationId: number, autoAssign: boolean) => void;
}

export function AdminLinkLocationsTable(props: Props) {
  const {
    adminLinkLocationsQueryResult,
    onNextPage,
    onPreviousPage,
    updateLinkLocationCategories,
    updateLinkLocation,
  } = props;

  const totalCount = adminLinkLocationsQueryResult.data?.linkLocations?.totalCount ?? 0;
  const linkLocations = adminLinkLocationsQueryResult.data?.linkLocations?.nodes ?? [];

  return (
    <>
      <Table isEmpty={linkLocations.length === 0} isLoading={adminLinkLocationsQueryResult.loading}>
        <TableHead>
          <TableRow key="head">
            <TableHeaderCell>Domain</TableHeaderCell>
            <TableHeaderCell>Publisher</TableHeaderCell>
            <TableHeaderCell>Type</TableHeaderCell>
            <TableHeaderCell>Created At</TableHeaderCell>
            <TableHeaderCell>Domain Authority</TableHeaderCell>
            <TableHeaderCell>Authority Score</TableHeaderCell>
            <TableHeaderCell>Monthly Traffic</TableHeaderCell>
            <TableHeaderCell>Quality Score</TableHeaderCell>
            <TableHeaderCell>Status</TableHeaderCell>
            <TableHeaderCell>Categories</TableHeaderCell>
            <TableHeaderCell>Auto Assign</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {linkLocations.map((linkLocation) => (
            <TableRow key={linkLocation.id}>
              <TableCell>
                <AppLink to={`/outreach/link-locations/${linkLocation.id}`}>{linkLocation.domain}</AppLink>
              </TableCell>
              <TableCell>{linkLocation.publisherLinkLocation?.publisher?.name ?? "-"}</TableCell>
              <TableCell>{linkLocation.type.name}</TableCell>
              <TableCell>{DateTime.fromJSDate(linkLocation.createdAt).toFormat(DATE_MONTH_FORMAT)}</TableCell>
              <TableCell>{linkLocation?.domainAuthority ?? "-"}</TableCell>
              <TableCell>{linkLocation?.authorityScore ?? "-"}</TableCell>
              <TableCell>{linkLocation?.monthlyTraffic ?? "-"}</TableCell>
              <TableCell>{linkLocation?.internalQuality ?? "-"}</TableCell>
              <TableCell>{linkLocation.status.name}</TableCell>
              <TableCell>
                <CategoryMultiSelector
                  selectedCategoryIds={linkLocation.linkLocationCategories.map((c) => c.categoryId)}
                  onCategorySelected={(categoryIds) => updateLinkLocationCategories(linkLocation.id, categoryIds)}
                  emptyValueLabel="No Category"
                />
              </TableCell>
              <TableCell>
                <Switch
                  onChange={updateLinkLocation.bind(null, linkLocation.id, linkLocation.autoAssign ?? false)}
                  isChecked={linkLocation.autoAssign ?? false}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFoot>
          <TablePagination
            totalCount={totalCount}
            offset={adminLinkLocationsQueryResult.variables?.offset ?? 0}
            limit={adminLinkLocationsQueryResult.variables?.limit ?? 50}
            onPreviousPage={onPreviousPage}
            onNextPage={onNextPage}
          />
        </TableFoot>
      </Table>
    </>
  );
}
