import { useContext } from "react";
import { DelimitedArrayParam, NumberParam, StringParam, useQueryParam, withDefault } from "use-query-params";

import { useTasksQuery, TasksQueryVariables } from "../../generated/graphql";
import { PAGE_LIMIT, OUTREACH_USER_ID } from "../../constants/constants";
import { TASK_STATUS } from "../../constants/task-status";
import { CS_VENDOR_ID } from "../../constants/vendors";
import { AuthContext } from "../../core/auth-manager";
import { SUB_TASK_TYPES } from "../../constants/task-types";

const VIEWS = {
  MY_TASKS: {
    id: "my-tasks",
    label: "Tasks Assigned to Me",
  },
  AVAILABLE_TASKS: {
    id: "available-tasks",
    label: "Available Tasks",
  },
};

export function useTasks() {
  const [pageOffset, setPageOffset] = useQueryParam("pageOffset", withDefault(NumberParam, 0));
  const [selectedView, setSelectedViewParam] = useQueryParam("view", withDefault(StringParam, VIEWS.MY_TASKS.id));
  const [productIds, setProductIdsParam] = useQueryParam("productIds", withDefault(DelimitedArrayParam, []));
  const [taskTypeIds, setTaskTypeIdsParam] = useQueryParam("taskTypeIds", withDefault(DelimitedArrayParam, []));

  const authContext = useContext(AuthContext);

  let filters: TasksQueryVariables = {};

  if (selectedView === VIEWS.MY_TASKS.id) {
    filters = {
      assignedUserIds: [authContext.viewer?.user?.id ?? 0, OUTREACH_USER_ID],
      statusIds: [TASK_STATUS.ASSIGNED.id],
    };
  } else if (selectedView === VIEWS.AVAILABLE_TASKS.id) {
    filters = {
      statusIds: [TASK_STATUS.AVAILABLE.id],
    };
  }

  let filterCount = 0;
  if (productIds.length > 0) {
    filterCount++;
  }
  if (taskTypeIds.length > 0) {
    filterCount++;
  }

  const tasksQueryResult = useTasksQuery({
    fetchPolicy: "network-only",
    variables: {
      limit: PAGE_LIMIT,
      offset: pageOffset,
      excludedVendorIds: [CS_VENDOR_ID],
      taskTypeIds: taskTypeIds as string[],
      notTaskTypeIds: Object.values(SUB_TASK_TYPES),
      productIds: productIds as string[],
      ...filters,
    },
    pollInterval: 10 * 1000, // 10 seconds
  });

  function onNextPage() {
    setPageOffset(pageOffset + PAGE_LIMIT);
  }

  function onPreviousPage() {
    setPageOffset(Math.max(pageOffset - PAGE_LIMIT, 0));
  }

  function onViewChange(value: string | null) {
    setSelectedViewParam(value);
    setPageOffset(0);
  }

  function onProductIdsChange(value: string[]) {
    setProductIdsParam(value);
    setPageOffset(0);
  }

  function onTaskTypeIdsChange(value: string[]) {
    setTaskTypeIdsParam(value);
    setPageOffset(0);
  }

  function clearFilters() {
    setProductIdsParam([]);
    setTaskTypeIdsParam([]);
    setPageOffset(0);
  }

  return {
    VIEWS,
    tasksQueryResult,
    filterCount,
    selectedView,
    productIds,
    taskTypeIds,
    clearFilters,
    onNextPage,
    onPreviousPage,
    onViewChange,
    onProductIdsChange,
    onTaskTypeIdsChange,
  };
}
