import { useRef } from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Stack,
  FormControl,
  FormLabel,
  Input,
  useToast,
  FormErrorMessage,
  Switch,
  Textarea,
  SimpleGrid,
  Box,
} from "@chakra-ui/react";
import * as yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { getDisplayMessageForError } from "../../util/error-helper";
import { useCreateClientKeywordMutation } from "../../generated/graphql";
import { FormHelpers } from "../../util/form-helpers";
import { KeywordLocationSelector } from "../keyword-location-selector";

interface Props {
  clientId: number;
  isOpen: boolean;
  onClose: () => void;
  onAdded: (clientKeywordId: number) => void;
}

interface FormValues {
  name: string;
  location?: string;
  country?: string;
  landingPageUrl?: string;
  instructions?: string;
  isPrimary: boolean;
  createAnother: boolean;
}

const createClientKeywordSchema = yup.object().shape({
  name: yup.string().label("Name").required().min(2).max(255),
  location: yup.string().label("Location").min(2).max(255),
  country: yup.string().label("Country").notRequired(),
  landingPageUrl: yup.string().label("Landing Page URL").url().notRequired().nullable(true),
  instructions: yup.string().label("Instructions"),
  isPrimary: yup.boolean().label("Is Primary").required(),
});

export function AddClientKeywordDrawer(props: Props) {
  const { onClose, onAdded, isOpen, clientId } = props;
  const [createClientKeyword] = useCreateClientKeywordMutation();
  const toast = useToast();
  const nameFieldRef = useRef<HTMLInputElement>(null);

  async function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    try {
      const response = await createClientKeyword({
        variables: {
          input: {
            customerId: clientId,
            name: values.name,
            location: FormHelpers.processNullableString(values.location ?? ""),
            country: FormHelpers.processNullableString(values.country ?? ""),
            landingPageUrl: FormHelpers.processNullableString(values.landingPageUrl ?? ""),
            instructions: values.instructions,
            isPrimary: values.isPrimary,
          },
        },
      });

      if (response.data?.createCustomerKeyword.customerKeyword?.id) {
        toast({ title: "Success", description: "Keyword created successfully.", status: "success" });
        formikHelpers.resetForm();
        onAdded(response.data.createCustomerKeyword.customerKeyword.id);
      }

      if (values.createAnother) {
        formikHelpers.resetForm();
        formikHelpers.setSubmitting(false);
        nameFieldRef.current?.focus();
      } else {
        onClose();
      }
    } catch (e: any) {
      toast({ title: "Error", description: getDisplayMessageForError(e), status: "error" });
      formikHelpers.setSubmitting(false);
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      name: "",
      location: "",
      country: "",
      landingPageUrl: "",
      instructions: "",
      isPrimary: true,
      createAnother: false,
    },
    validationSchema: createClientKeywordSchema,
    onSubmit: handleSubmit,
  });

  function handleCancel() {
    formik.resetForm();
    onClose();
  }

  function handleSave(andCreateAnother: boolean) {
    formik.setFieldValue("createAnother", andCreateAnother);
    formik.submitForm();
  }

  return (
    <Drawer isOpen={isOpen} onClose={handleCancel} placement="right" size="sm" closeOnOverlayClick={false}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Add Keyword</DrawerHeader>
        <DrawerBody>
          <Stack spacing={4}>
            <FormControl isRequired isInvalid={!!formik.errors.name && !!formik.touched.name}>
              <FormLabel>Name</FormLabel>
              <Input
                type="text"
                variant="outline"
                id="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                ref={nameFieldRef}
              />
              {!!formik.touched.name && formik.errors.name && <FormErrorMessage>{formik.errors.name}</FormErrorMessage>}
            </FormControl>
            <FormControl isInvalid={!!formik.errors.location && !!formik.touched.location}>
              <KeywordLocationSelector
                location={formik.values.location ?? ""}
                onLocationChange={(value) => formik.setFieldValue("location", value)}
                country={formik.values.country ?? ""}
                onCountryChange={(value) => formik.setFieldValue("country", value)}
              />
              {!!formik.touched.location && formik.errors.location && (
                <FormErrorMessage>{formik.errors.location}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={!!formik.errors.landingPageUrl && !!formik.touched.landingPageUrl}>
              <FormLabel>Landing Page URL</FormLabel>
              <Input
                type="text"
                variant="outline"
                id="landingPageUrl"
                value={formik.values.landingPageUrl}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {!!formik.touched.landingPageUrl && formik.errors.landingPageUrl && (
                <FormErrorMessage>{formik.errors.landingPageUrl}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isRequired isInvalid={!!formik.errors.isPrimary && !!formik.touched.isPrimary}>
              <FormLabel>Primary</FormLabel>
              <Box>
                <Switch
                  size="md"
                  isChecked={formik.values.isPrimary}
                  id="isPrimary"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Box>
              {!!formik.touched.isPrimary && formik.errors.isPrimary && (
                <FormErrorMessage>{formik.errors.isPrimary}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={!!formik.errors.instructions && !!formik.touched.instructions}>
              <FormLabel>Instructions</FormLabel>
              <Textarea
                id="instructions"
                value={formik.values.instructions ?? ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                resize="none"
                placeholder="Instructions"
                variant="outline"
              />
              {!!formik.touched.instructions && formik.errors.instructions && (
                <FormErrorMessage>{formik.errors.instructions}</FormErrorMessage>
              )}
            </FormControl>
          </Stack>
        </DrawerBody>
        <DrawerFooter>
          <SimpleGrid columns={2} gridGap={4} width="100%">
            <PrimaryButton width="100%" onClick={handleSave.bind(null, false)}>
              Save
            </PrimaryButton>
            <SecondaryButton width="100%" onClick={handleSave.bind(null, true)}>
              Save &amp; Add Another
            </SecondaryButton>
          </SimpleGrid>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
