import {
  useSaveCustomerQuestionnaireMutation,
  useCustomerDetailsQuestionnaireQuery,
  useCustomerSiteCredentialQuery,
  useSaveCustomerSiteCredentialMutation,
  useUpdateCustomerMutation,
} from "../../generated/graphql";
import { useFormik } from "formik";
import { useToast } from "@chakra-ui/react";
import { handleFormGraphQLError, handleGraphQLResponseError } from "../../util/error-helper";
import { FormHelpers } from "../../util/form-helpers";
import * as yup from "yup";
import { useShowPasswordTrack } from "../../common/use-show-password-track";

interface WebsiteFormValues {
  websiteHosting: string;
  websiteCms: string;
  whoCanChangeWebsite: string;
  contentPreference: string;
}

const websiteSchema = yup.object().shape({
  whoCanChangeWebsite: yup.string().label("Who Can Change Website").required(),
});

const requiredIfRCMPublishing = yup.string().when("isRCMPublishing", {
  is: true,
  then: yup.string().required(),
  otherwise: yup.string().notRequired(),
});

const contentSchema = yup.object().shape({
  contentPublishingPermissions: yup.string().label("Content Publishing Permissions").required().nullable(false),
  targetAudience: yup.string().label("Target Audience").required(),
  preferredVoice: yup.string().label("Brand Voice").required(),
  isRCMPublishing: yup.boolean().label("Is RCM Publishing").notRequired().default(false).nullable(false),
  isFulfilledByRCMWriter: yup
    .boolean()
    .label("Is Fulfilled By RCM Writer")
    .notRequired()
    .default(false)
    .nullable(false),
  isAddImage: yup.string().label("Is Add Image").notRequired().nullable(true),
  adminUrl: requiredIfRCMPublishing,
  user: requiredIfRCMPublishing,
  // password not validated as in update we are not querying password it will be empty
});

interface ContentFormValues {
  contentPublishingPermissions: string;
  isRCMPublishing: boolean;
  isBlogFulfilledByRCMWriter: boolean;
  isGuestArticleFulfilledByRCMWriter: boolean;
  isAutoSelectLinkLocation: boolean;
  isAddImage: string;
  adminUrl: string;
  user: string;
  password: string;
  instructions: string;
  preferredVoice: string;
  targetAudience: string;
  competitorWebsites: string;
  purposeOfContent: string;
  callToActionUrls: string;
  targetKeywords: string;
}

export function useClientDetailsQuestionnaire(customerId: number) {
  const customerQuestionnaireQueryResult = useCustomerDetailsQuestionnaireQuery({
    variables: {
      id: customerId,
    },
  });
  const [saveCustomerQuestionnaireMutation] = useSaveCustomerQuestionnaireMutation();
  const [updateCustomer] = useUpdateCustomerMutation();

  const customerSiteCredentialQueryResult = useCustomerSiteCredentialQuery({
    variables: {
      id: customerId,
    },
  });
  const [saveCustomerSiteCredential] = useSaveCustomerSiteCredentialMutation();

  const toast = useToast();

  const customer = customerQuestionnaireQueryResult.data?.customer ?? null;
  const customerQuestionnaire = customerQuestionnaireQueryResult.data?.customer?.customerQuestionnaire ?? null;
  const customerSiteCredential = customerSiteCredentialQueryResult.data?.customer?.customerSiteCredential ?? null;

  const { onPasswordClick, showPassword } = useShowPasswordTrack({
    entityId: customerSiteCredential?.id,
    entityName: customerSiteCredential?.__typename,
  });

  const websiteFormik = useFormik<WebsiteFormValues>({
    initialValues: {
      websiteCms: customerQuestionnaire?.websiteCms ?? "",
      websiteHosting: customerQuestionnaire?.websiteHosting ?? "",
      whoCanChangeWebsite: customerQuestionnaire?.whoCanChangeWebsite ?? "",
      contentPreference: customerQuestionnaire?.contentPreference ?? "",
    },
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values, formikHelpers) => {
      try {
        const response = await saveCustomerQuestionnaireMutation({
          variables: {
            input: {
              customerId,
              websiteCms: FormHelpers.processNullableString(values.websiteCms),
              websiteHosting: FormHelpers.processNullableString(values.websiteHosting),
              whoCanChangeWebsite: FormHelpers.processNullableString(values.whoCanChangeWebsite),
              contentPreference: FormHelpers.processNullableString(values.contentPreference),
            },
          },
        });

        if (response.data?.saveCustomerQuestionnaire.customerQuestionnaire.id) {
          toast({ title: "Questionnaire updated.", description: "Your updates were saved.", status: "success" });
          await customerQuestionnaireQueryResult.refetch();
        }
      } catch (e: any) {
        handleFormGraphQLError(e, "Unable to Save Questionnaire updates", toast, formikHelpers.setErrors);
      }
    },
    validationSchema: websiteSchema,
  });

  const contentFormik = useFormik<ContentFormValues>({
    initialValues: {
      preferredVoice: customerQuestionnaire?.preferredVoice ?? "",
      targetAudience: customerQuestionnaire?.targetAudience ?? "",
      competitorWebsites: customerQuestionnaire?.competitorWebsites ?? "",
      purposeOfContent: customerQuestionnaire?.purposeOfContent ?? "",
      callToActionUrls: customerQuestionnaire?.callToActionUrls ?? "",
      targetKeywords: customerQuestionnaire?.targetKeywords ?? "",
      contentPublishingPermissions: customerQuestionnaire?.contentPublishingPermissions ?? "",
      isRCMPublishing: customerQuestionnaire?.isRCMPublishing ?? false,
      isBlogFulfilledByRCMWriter: customer?.isBlogFulfilledByRCMWriter ?? true,
      isGuestArticleFulfilledByRCMWriter: customer?.isGuestArticleFulfilledByRCMWriter ?? false,
      isAutoSelectLinkLocation: customer?.isAutoSelectLinkLocation ?? false,
      isAddImage: customerQuestionnaire?.isAddImage !== null ? (customerQuestionnaire?.isAddImage ? "1" : "0") : "",
      adminUrl: customerSiteCredential?.adminUrl ?? "",
      user: customerSiteCredential?.user ?? "",
      password: customerSiteCredential?.password ?? "",
      instructions: customerSiteCredential?.instructions ?? "",
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: contentSchema,
    onSubmit: async (values, formikHelpers) => {
      try {
        const response = await saveCustomerQuestionnaireMutation({
          variables: {
            input: {
              customerId,
              competitorWebsites: FormHelpers.processNullableString(values.competitorWebsites),
              preferredVoice: FormHelpers.processNullableString(values.preferredVoice),
              targetAudience: FormHelpers.processNullableString(values.targetAudience),
              purposeOfContent: FormHelpers.processNullableString(values.purposeOfContent),
              callToActionUrls: FormHelpers.processNullableString(values.callToActionUrls),
              targetKeywords: FormHelpers.processNullableString(values.targetKeywords),
              contentPublishingPermissions: FormHelpers.processNullableString(values.contentPublishingPermissions),
              isRCMPublishing: values.isRCMPublishing,
              isAddImage: values.isAddImage !== "" ? values.isAddImage === "1" : undefined,
            },
          },
        });

        const customerResposne = await updateCustomer({
          variables: {
            input: {
              customerId,
              isBlogFulfilledByRCMWriter: values.isBlogFulfilledByRCMWriter,
              isGuestArticleFulfilledByRCMWriter: values.isGuestArticleFulfilledByRCMWriter,
              isAutoSelectLinkLocation: values.isAutoSelectLinkLocation,
            },
          },
        });

        if (
          response.data?.saveCustomerQuestionnaire.customerQuestionnaire.id &&
          customerResposne.data?.updateCustomer.customer?.id
        ) {
          if (!values.isRCMPublishing) {
            toast({ title: "Questionnaire updated.", description: "Your updates were saved.", status: "success" });
          }
          await customerQuestionnaireQueryResult.refetch();
        }

        if (values.isRCMPublishing) {
          const credentialResponse = await saveCustomerSiteCredential({
            variables: {
              input: {
                customerId,
                adminUrl: values.adminUrl,
                user: values.user,
                password: values.password,
                instructions: FormHelpers.processNullableString(values.instructions),
              },
            },
          });

          if (credentialResponse.data?.saveCustomerSiteCredential.ok) {
            toast({ title: "Questionnaire updated.", description: "Your updates were saved.", status: "success" });
            await customerSiteCredentialQueryResult.refetch();
          } else {
            if (credentialResponse.data?.saveCustomerSiteCredential.error) {
              handleGraphQLResponseError(
                credentialResponse.data.saveCustomerSiteCredential.error,
                toast,
                formikHelpers.setErrors
              );
            } else {
              throw new Error("Something went wrong!");
            }
          }
        }
      } catch (e: any) {
        handleFormGraphQLError(e, "Unable to Save Questionnaire updates", toast, formikHelpers.setErrors);
      }
    },
  });

  return {
    isQuestionnaireLoading: customerQuestionnaireQueryResult.loading,
    customer,
    customerQuestionnaire,
    websiteFormik,
    contentFormik,
    onPasswordClick,
    showPassword,
    refetch: customerQuestionnaireQueryResult.refetch,
  };
}
